import { FC } from 'react'
import { Box, Typography } from '@mui/material'

const ProtocolAuestionsAnswers: FC<{
  questions: {
    id: number
    room: {
      id: number
      roomInstance: {
        id: number
        name: string
        nameLocal: string
        flat: {
          id: number
          area: number
          floor: number
          flatIdNumber: string
          numberOfRooms: number
          flatType: number
          flatTypeExtra: number
        }
        area: number
      }
    }
    question: {
      id: number
      roomType: number
      question: string
    }
    scale: string
    comment: string
    images: { id: number; image: string }[]
    debitNotePosition: {
      id: number
      title: string
      productCode: number
    }
    coveringCost: string
  }[]
}> = ({ questions }) => {
  if (questions.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography>Brak pytań</Typography>
      </Box>
    )
  }

  return (
    <>
      {questions.map((question) => (
        <Box key={question.id} mb={2}>
          <Typography fontWeight={800}>{question.question.question}</Typography>
          {question.scale === 'Issue' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'start',
                borderBottom: '1px solid #CDCDCD',
                paddingBottom: '20px'
              }}>
              <Box sx={{ marginBottom: '10px' }}>
                <Typography>Uwagi: {question.comment}</Typography>
                <Typography>Koszt pokrycia: {question.coveringCost}</Typography>
                <Typography>Nota obciążeniowa: {question.debitNotePosition.title}</Typography>
                <Typography>Zdjęcia: {!question.images.length ? 'brak' : ''}</Typography>
              </Box>
              {question.images.map((image) => (
                <Box
                  sx={{
                    width: 'auto',
                    maxHeight: '320px',
                    maxWidth: '320px',
                    objectFit: 'contain'
                  }}
                  component='img'
                  key={image.id}
                  srcSet={image.image}
                  src={image.image}
                  alt={image.id.toString()}
                  loading='lazy'
                />
              ))}
            </Box>
          ) : (
            <Typography>Brak zastrzeżeń</Typography>
          )}
        </Box>
      ))}
    </>
  )
}

export default ProtocolAuestionsAnswers
