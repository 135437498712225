import { apiInstance } from 'services/api'
import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { accessTokenAtom, refreshTokenAtom } from 'services/token/token.model'

interface UseUserRegisterParams {
  onError: () => void
}

interface UserRegisterRequest {
  firstName: string
  lastName: string
  email: string
  phone: string
  password: string
  appChannelConsent: boolean
  emailChannelConsent: boolean
  marketingChannelConsent: boolean
}

interface UserRegisterResponse {
  token: { access: string; refresh: string }
  user: {
    firstName: string
    lastName: string
    email: string
    phone: string
  }
}

export const useUserRegister = ({ onError }: UseUserRegisterParams) => {
  const setAccessToken = useSetAtom(accessTokenAtom)
  const setRefreshToken = useSetAtom(refreshTokenAtom)

  return useMutation({
    mutationFn: (data: UserRegisterRequest) =>
      apiInstance<UserRegisterResponse>({
        url: '/users/register/',
        method: 'POST',
        data
      }),
    onSuccess: ({ data }) => {
      setAccessToken(data.token.access)
      setRefreshToken(data.token.refresh)
    },
    onError
  })
}

export const useUserPasswordRequestReset = () => {
  const { mutateAsync, ...rest } = useMutation({
    mutationFn: (email: UserRegisterRequest['email']) =>
      apiInstance<void>({
        url: '/users/password/request/reset/',
        method: 'POST',
        data: {
          email
        }
      })
  })

  return {
    passwordRequestReset: mutateAsync,
    ...rest
  }
}

export const useUserPasswordReset = () => {
  const { mutateAsync, ...rest } = useMutation({
    mutationFn: ({ password, resetKey }: { password: string; resetKey: string }) =>
      apiInstance<void>({
        url: '/users/password/reset/',
        method: 'POST',
        data: {
          password,
          resetKey
        }
      })
  })

  return {
    passwordReset: mutateAsync,
    ...rest
  }
}
