// @ts-nocheck
import { FC, useState, useMemo } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Typography
} from '@mui/material'
// import { useIntl } from 'react-intl'
import { PlayArrow, Image, PriorityHigh, Remove, Message } from '@mui/icons-material'
import ResponsiveFullScreenDialog from 'components/ResponsiveFullScreenDialog'
import ProtocolQuestionsAnswers from './ProtocolQuestionsAnswers'
import ProtocolComments from './ProtocolComments'

const ProtocolRooms: FC<{
  rooms: {
    id: number
    roomInstance: {
      id: number
      name: string
      nameLocal: string
      flat: {
        id: number
        area: number
        floor: number
        flatIdNumber: string
        numberOfRooms: number
        flatType: number
        flatTypeExtra: number
      }
      area: number
    }
    video: string
    description: string
    images: {
      id: number
      room: number
      image: string
    }[]
  }[]
  questionsAnswers: {
    id: number
    room: {
      id: number
      roomInstance: {
        id: number
        name: string
        nameLocal: string
        flat: {
          id: number
          area: number
          floor: number
          flatIdNumber: string
          numberOfRooms: number
          flatType: number
          flatTypeExtra: number
        }
        area: number
      }
    }
    question: {
      id: number
      roomType: number
      question: string
    }
    scale: string
    comment: string
    image: string
    debitNotePosition: {
      id: number
      title: string
      productCode: number
    }
    coveringCost: string
  }[]
  personComments: {
    id: number
    room: number
    comment: string
    image: string
  }[]
  protocolId: number
}> = ({ rooms, questionsAnswers, personComments, protocolId }) => {
  const [selectedRoom, setSelectedRoom] = useState({
    id: -1,
    roomInstance: -1
  })
  const [isImagesModalOpen, setImagesModalOpen] = useState(false)
  const [isPersonCommentsModalOpen, setPersonCommentsModalOpen] = useState(false)
  const [isQuestionsAnswersModalOpen, setQuestionsAnswersModalOpen] = useState(false)

  const comments = useMemo(
    () => personComments.filter(({ room }) => room === selectedRoom.id),
    [personComments, selectedRoom.id]
  )

  const images = useMemo(
    () => rooms.find(({ id }) => id === selectedRoom.id)?.images ?? [],
    [rooms, selectedRoom.id]
  )

  const questions = useMemo(
    () =>
      questionsAnswers.filter(
        ({ room }) =>
          room.id === selectedRoom.id && room.roomInstance.id === selectedRoom.roomInstance
      ),
    [questionsAnswers, selectedRoom]
  )

  const getQuestionCountForRoom = (roomId, data) => {
    return data.filter((entry) => entry.room.id === roomId).length
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>Nazwa</TableCell>
              <TableCell sx={{ fontWeight: 700 }} align='right'>
                Opis
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }} align='right'>
                Wideo
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }} align='right'>
                Zdjęcia
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }} align='right'>
                Uwagi
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }} align='right'>
                Komentarze
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
          {rooms.map((room) => {
            const questionCount = getQuestionCountForRoom(room.id, questionsAnswers)
            return (
              <TableRow key={room.id}>
                <TableCell component='th' scope='row'>
                  {room.roomInstance.nameLocal ?? room.roomInstance.name}
                </TableCell>
                <TableCell align='right'>{room.description ?? 'n/a'}</TableCell>
                <TableCell align='right'>
                  {room.video ? (
                    <IconButton
                      LinkComponent='a'
                      href={room.video}
                      target='_blank'
                      rel='noreferrer'>
                      <PlayArrow />
                    </IconButton>
                  ) : (
                    'n/a'
                  )}
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    onClick={() => {
                      setImagesModalOpen(true)
                      setSelectedRoom({
                        id: room.id,
                        roomInstance: room.roomInstance.id
                      })
                    }}>
                    <Image />
                  </IconButton>
                </TableCell>
                <TableCell align='right'>
                  {questionCount < 0 ? (
                    <IconButton disabled>
                      <Remove />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setQuestionsAnswersModalOpen(true)
                        setSelectedRoom({
                          id: room.id,
                          roomInstance: room.roomInstance.id
                        })
                      }}>
                      <PriorityHigh />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    onClick={() => {
                      setPersonCommentsModalOpen(true)
                      setSelectedRoom({
                        id: room.id,
                        roomInstance: room.roomInstance.id
                      })
                    }}>
                    <Message />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
      </TableContainer>
      <ResponsiveFullScreenDialog
        title='Zdjęcia'
        isOpen={isImagesModalOpen}
        onClose={() => setImagesModalOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          {images.length === 0 && <Typography>Brak zdjęć</Typography>}
          {images.map((image) => (
            <Box
              sx={{ width: 'auto', maxHeight: '320px' }}
              component='img'
              key={image.id}
              srcSet={image.image}
              src={image.image}
              alt={image.id.toString()}
              loading='lazy'
            />
          ))}
        </Box>
      </ResponsiveFullScreenDialog>
      <ResponsiveFullScreenDialog
        title='Komentarze'
        isOpen={isPersonCommentsModalOpen}
        onClose={() => setPersonCommentsModalOpen(false)}>
        <ProtocolComments comments={comments} protocolId={protocolId} roomId={selectedRoom.id} />
      </ResponsiveFullScreenDialog>
      <ResponsiveFullScreenDialog
        title='Uwagi'
        isOpen={isQuestionsAnswersModalOpen}
        onClose={() => setQuestionsAnswersModalOpen(false)}>
        <ProtocolQuestionsAnswers questions={questions} />
      </ResponsiveFullScreenDialog>
    </>
  )
}

export default ProtocolRooms
