import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface HelpdeskTicketsListResponse {
  dateOfCompletion: boolean | undefined
  id: number
  topic: string
  description: string
  faultCategory: null | {
    id: number
    name: string
    iconName: string
    parentCategoryId: number
  }
  faultDate: null | string
  createdAt: string
  status: { code: string; name: string }
  authorPerson: {
    id: number
    firstName: string
    lastName: string
  }
}

export const useHelpdeskTicketsList = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const {
    data: ticketsList = [],
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['ticketsList', masquaradeToken],
    cacheTime: 5000,
    staleTime: 5000,

    queryFn: () =>
      apiInstance<HelpdeskTicketsListResponse[]>({
        url: '/helpdesk/tickets/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const getTicket = useCallback(
    (id: number) => ticketsList.find((ticket) => ticket.id === id),
    [ticketsList]
  )

  const getSatusColors = useCallback((code: string) => {
    switch (code) {
      case 'DURING':
        return {
          backgroundColor: '#CEFFF2',
          color: '#00B383'
        }

      case 'READY':
        return {
          backgroundColor: '#F2F6FF',
          color: '#8F9BB3'
        }

      default:
        return {
          backgroundColor: '#527EF7',
          color: '#ffffff'
        }
    }
  }, [])

  return {
    ticketsList,
    refetchTicketsList: refetch,
    getTicket,
    getSatusColors,
    ...rest
  }
}
