import { FC } from 'react'
import { Defect } from 'components/Defects'
import { UserProtectedRoute } from 'routes'
import { useHelpdeskTicketsList } from 'services/helpdesk/hooks'
import { useParams } from 'react-router-dom'
import DefectFeedback from 'components/Defects/DefectFeedback'
import Section from 'components/Section'
import { CircularProgress } from '@mui/material'

const FaultsSinglePage: FC = () => {
  const { id } = useParams()
  const { getTicket, isLoading, isFetching } = useHelpdeskTicketsList()
  const ticket = getTicket(Number(id))

  if (isFetching || isLoading) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Section>
    )
  }

  return (
    <UserProtectedRoute>
      {ticket?.status.code === 'READY' ? <DefectFeedback /> : <Defect />}
    </UserProtectedRoute>
  )
}

export default FaultsSinglePage
