import {
  AccountCircleOutlined,
  KeyboardArrowRight,
  NotificationsOutlined,
  PhoneIphoneOutlined,
  SettingsOutlined,
  ExitToApp,
  SupervisorAccount
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Badge,
  Tooltip,
  Select,
  MenuItem,
  useTheme
} from '@mui/material'
import { AppVersion } from 'components/AppVersion'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useUsersProfile } from 'services/profile/profile.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeToken, useMasquaradeUser } from 'services/masquarade/masquarade.hooks'
import { MasquaradeListItem } from 'components/Masquarade'
import { useIntl } from 'react-intl'
import { useLanguage } from 'services/i18n/i18n.hooks'

const UserProfile: FC = () => {
  const { formatMessage } = useIntl()
  const { clearUserData } = useAccessToken()
  const { isMasquarade, clearMasquaradeToken } = useMasquaradeToken()
  const { clearMasquaradeUser } = useMasquaradeUser()
  const navigate = useNavigate()
  const { data = { firstName: '', lastName: '' }, userInitials } = useUsersProfile()
  const { language, setLanguage, AVAILABLE_LANGUAGES } = useLanguage()
  const theme = useTheme()

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userProfile.profile',
          defaultMessage: 'Profile'
        })}
      />
      <BackNavigationContent>
        <Box
          pt='env(safe-area-inset-top)'
          mb={3}
          display='flex'
          justifyContent='flex-start'
          alignItems='center'>
          <Box mr={2}>
            {isMasquarade ? (
              <Badge
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <Tooltip
                    title={formatMessage({
                      id: 'userProfile.supervisorAccount',
                      defaultMessage: 'Maskarada'
                    })}>
                    <Avatar
                      sx={{
                        width: 35,
                        height: 35,
                        bgcolor: 'primary.main'
                      }}>
                      <SupervisorAccount />
                    </Avatar>
                  </Tooltip>
                }>
                <Avatar sx={{ width: 85, height: 85 }}>{userInitials}</Avatar>
              </Badge>
            ) : (
              <Avatar sx={{ width: 85, height: 85 }}>{userInitials}</Avatar>
            )}
          </Box>
          <Box>
            <Typography>
              {data.firstName} {data.lastName}
            </Typography>
          </Box>
        </Box>
        <List sx={{ paddingBottom: [0, 8] }}>
          <ListItem
            onClick={() => navigate('/user/account')}
            disablePadding
            sx={{
              borderBottom: '1px solid #CDCDCD',
              borderTop: '1px solid #CDCDCD'
            }}
            secondaryAction={<KeyboardArrowRight />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <SettingsOutlined color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: 'userProfile.account',
                  defaultMessage: 'Zarządzanie kontem'
                })}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => navigate('/user/personal-data')}
            disablePadding
            sx={{ borderBottom: '1px solid #CDCDCD' }}
            secondaryAction={<KeyboardArrowRight />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <AccountCircleOutlined color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: 'userProfile.personalData',
                  defaultMessage: 'Dane osobowe'
                })}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => navigate('/user/details')}
            disablePadding
            sx={{ borderBottom: '1px solid #CDCDCD' }}
            secondaryAction={<KeyboardArrowRight />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <PhoneIphoneOutlined color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: 'userProfile.details',
                  defaultMessage: 'Dane kontaktowe'
                })}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => navigate('/user/constens')}
            disablePadding
            sx={{ borderBottom: '1px solid #CDCDCD' }}
            secondaryAction={<KeyboardArrowRight />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <NotificationsOutlined color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: 'userProfile.constens',
                  defaultMessage: 'Zgody i powiadomienia'
                })}
              />
            </ListItemButton>
          </ListItem>
          <MasquaradeListItem />
        </List>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: '2rem',
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              bottom: 1,
              left: '1rem',
              width: 'calc(100% - 2rem)'
            }
          }}>
          <Button
            color='error'
            onClick={() => {
              clearUserData()
              clearMasquaradeToken()
              clearMasquaradeUser()
            }}
            startIcon={<ExitToApp />}>
            {formatMessage({
              id: 'userProfile.logout',
              defaultMessage: 'Wyloguj'
            })}
          </Button>
          <Select value={language} onChange={(event) => setLanguage(event.target.value)}>
            {AVAILABLE_LANGUAGES.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <AppVersion />
      </BackNavigationContent>
    </>
  )
}

export default UserProfile
