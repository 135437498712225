import { FC, useState, useEffect } from 'react'
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'
import { Typography } from '@mui/material'

const AppVersion: FC = () => {
  const [version, setVersion] = useState<string | null>(null)

  useEffect(() => {
    Device.getInfo().then((info) => {
      if (info.platform !== 'web') {
        App.getInfo().then((app) => {
          setVersion(app.version)
        })
      }
    })
  }, [])

  if (version) {
    return (
      <Typography
        variant='caption'
        sx={{
          display: 'block',
          textAlign: 'center',
          width: '100%'
        }}>
        Ver.: {version}
      </Typography>
    )
  }

  return null
}

export default AppVersion
