import { FC } from 'react'
import { Typography, Button, CircularProgress } from '@mui/material'
import { useIntl } from 'react-intl'
import Section from 'components/Section'
import { useNavigate } from 'react-router-dom'
import { useOfferList } from 'services/offer/hooks'

const WidgetOffer: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data: offerList = [], isFetching, isLoading } = useOfferList()

  if (isFetching || isLoading) {
    return (
      <Section
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '117px'
        }}>
        <CircularProgress />
      </Section>
    )
  }

  if (offerList.length < 1) {
    return null
  }

  return (
    <Section
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '15px'
      }}>
      <Typography variant='h5' fontWeight={500}>
        {formatMessage({
          id: 'widgetOffer.title',
          defaultMessage: 'Oferty'
        })}
      </Typography>
      <Button variant='outlined' fullWidth onClick={() => navigate(`/offer`)}>
        {formatMessage({
          id: 'widgetOffer.button',
          defaultMessage: 'Przejdź do ofert'
        })}
      </Button>
    </Section>
  )
}

export default WidgetOffer
