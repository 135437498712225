import { Box, Button, CircularProgress, Dialog, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useLanguage } from 'services/i18n/i18n.hooks'
import { InsertPhotoOutlined, HelpOutline } from '@mui/icons-material'
import ImageGallery from 'react-image-gallery'
import MuiMarkdown, { getOverrides } from 'mui-markdown'
import OfferMap from '../OfferMap'
import { getLatLng, GetPhotoLabel } from 'utils'
import dayjs from 'dayjs'
import 'react-image-gallery/styles/css/image-gallery.css'
import { useOfferListOffer } from 'services/offer/hooks/useOfferList'
import Section from 'components/Section'
import { useOffer } from 'services/offer/hooks'
import { LoadingButton } from '@mui/lab'

const OfferDetailsView: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const { language } = useLanguage()
  const { offerAccept } = useOffer()
  const { data: offerDetail = [], isFetching, isLoading } = useOfferListOffer(id)
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const [isDescOpen, setIsDescOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (isFetching || isLoading) {
    return (
      <Section
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <CircularProgress />
      </Section>
    )
  }

  const photoCount = offerDetail.flat.photos.length
  const photoLabel = GetPhotoLabel(photoCount)
  const offerImages = offerDetail.flat.photos.map((photo: { url: string }) => ({
    original: photo.url
  }))
  const validToDate = dayjs(offerDetail.validTo)
  const today = dayjs()

  const handleAcceptOffer = async () => {
    setIsSubmitting(true)
    try {
      await offerAccept({ id })
    } catch (error) {
      console.log(error)
    }
    navigate(`/offer/${id}/accept`)
    setIsSubmitting(false)
  }

  return (
    <Box
      sx={{
        maxWidth: '600px',
        width: '100%',
        margin: 'auto'
      }}>
      {offerDetail.flat.photos.length > 0 ? (
        <>
          <Box
            sx={{
              position: { xs: 'absolute', lg: 'relative' },
              top: 0,
              left: 0,
              marginTop: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '334px',
                objectFit: 'cover'
              }}
              component='img'
              srcSet={offerDetail.flat.photos[0].file}
              src={offerDetail.flat.photos[0].file}
              loading='lazy'
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                bottom: '18px',
                right: '14px',
                paddingX: '16px',
                paddingY: '3px',
                backgroundColor: '#fff',
                borderRadius: '35px',
                gap: '8px',
                cursor: 'pointer'
              }}
              onClick={() => setIsGalleryOpen(true)}>
              <InsertPhotoOutlined sx={{ color: '#707070', width: '32px', height: '32px' }} />
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  gap: '6px'
                }}>
                <Typography sx={{ fontWeight: 700, fontSize: '15px', color: '#707070' }}>
                  {offerDetail.flat.photos.length}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '15px',
                    color: '#707070',
                    textTransform: 'uppercase'
                  }}>
                  {photoLabel}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Dialog open={isGalleryOpen} onClose={() => setIsGalleryOpen(false)}>
            <ImageGallery
              items={offerImages}
              showFullscreenButton={false}
              showPlayButton={false}
              disableSwipe={false}
              showIndex
            />
          </Dialog>
        </>
      ) : (
        <Box
          sx={{
            position: { xs: 'absolute', lg: 'relative' },
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: offerDetail.flat.photos.length > 0 ? '334px' : '100px',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Typography
              sx={{
                textAlign: 'center',
                marginTop: '170px'
              }}>
              {formatMessage({
                id: 'offer.noImages',
                defaultMessage: 'Brak zdjęć'
              })}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          marginTop: {
            xs: '285px',
            lg: '15px'
          }
        }}>
        <Button variant='outlined' fullWidth onClick={() => navigate(`/offer/${id}/messages`)}>
          {formatMessage({
            id: 'widgetOffer.askQuestion',
            defaultMessage: 'Zadaj pytanie'
          })}
        </Button>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '32px',
            paddingBottom: '30px',
            borderBottom: '1px solid #CDCDCD'
          }}>
          <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
            {formatMessage({
              id: 'widgetOffer.price',
              defaultMessage: 'Cena'
            })}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {+offerDetail.price} zł
            </Typography>
            <HelpOutline
              sx={{ width: '22px', height: '22px' }}
              onClick={() => navigate(`/offer/${id}/price-details`)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '30px',
            paddingBottom: '32px',
            borderBottom: '1px solid #CDCDCD',
            gap: '16px'
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.surface',
                defaultMessage: 'Powierzchnia'
              })}
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              {offerDetail.flat.area.toFixed(1)}m2
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.floor',
                defaultMessage: 'Piętro'
              })}
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              {offerDetail.flat.floor}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.flatNumber',
                defaultMessage: 'Numer mieszkania'
              })}
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              {offerDetail.flat.flatFinalNumber}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.numberOfRooms',
                defaultMessage: 'Liczba pokoi'
              })}
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              {offerDetail.flat.numberOfRooms}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.buildYear',
                defaultMessage: 'Rok budowy'
              })}
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              {offerDetail.flat.buildYear}
            </Typography>
          </Box>
        </Box>
        {offerDetail.flat.conveniences.length > 1 && (
          <Box
            sx={{
              paddingTop: '32px',
              paddingBottom: '42px',
              borderBottom: '1px solid #CDCDCD'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.conveniences',
                defaultMessage: 'Udogodnienia'
              })}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginTop: '16px'
              }}>
              {offerDetail.flat.conveniences.map((space: any) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    gap: '16px'
                  }}>
                  <img src={space.iconUrl} alt='icon' width={24} height={24} />
                  <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                    {space.nameLocal}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {offerDetail.flat.brochure && offerDetail.flat.brochureEn && (
          <Box
            sx={{
              paddingTop: '32px',
              paddingBottom: '30px',
              borderBottom: '1px solid #CDCDCD'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.estatePlan',
                defaultMessage: 'Plan nieruchomości'
              })}
            </Typography>
            <Button
              variant='outlined'
              fullWidth
              sx={{ marginTop: '20px' }}
              component='a'
              href={language === 'pl' ? offerDetail.flat.brochure : offerDetail.flat.brochureEn}
              target='_blank'>
              {formatMessage({
                id: 'widgetOffer.seePlan',
                defaultMessage: 'Zobacz plan'
              })}
            </Button>
          </Box>
        )}
        {offerDetail.flat.shortDescription || offerDetail.flat.shortDescriptionLocal ? (
          <Box
            sx={{
              paddingTop: '32px',
              paddingBottom: '30px',
              borderBottom: '1px solid #CDCDCD'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.locationDescription',
                defaultMessage: 'Opis lokalizacji'
              })}
            </Typography>
            <MuiMarkdown
              overrides={{
                ...getOverrides({}),
                p: {
                  component: 'p',
                  props: {
                    style: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: `${isDescOpen ? '' : 7}`,
                      WebkitBoxOrient: 'vertical',
                      paddingTop: '14px',
                      marginBottom: '8px',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '21px'
                    }
                  }
                }
              }}>
              {language === 'pl'
                ? offerDetail.flat.shortDescriptionLocal
                : offerDetail.flat.shortDescription}
            </MuiMarkdown>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Typography
                onClick={() => setIsDescOpen(!isDescOpen)}
                sx={{
                  fontWeight: 700,
                  fontSize: '15px',
                  color: '#3F51B5',
                  textTransform: 'uppercase',
                  cursor: 'pointer'
                }}>
                {formatMessage({
                  id: `${isDescOpen ? 'widgetOffer.seeLess' : 'widgetOffer.seeMore'}`,
                  defaultMessage: `${isDescOpen ? 'Zobacz mniej' : 'Zobacz wiecej'}`
                })}
              </Typography>
              {offerDetail?.flat?.geoposition && (
                <Button variant='contained' onClick={() => navigate(`/offer/${id}/pois-map`)}>
                  {formatMessage({
                    id: 'offer.seeOnMap',
                    defaultMessage: 'Zobacz na mapie'
                  })}
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        {offerDetail.flat.flatSpaces.length > 1 && (
          <Box
            sx={{
              paddingTop: '32px',
              paddingBottom: '30px',
              borderBottom: '1px solid #CDCDCD'
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {formatMessage({
                id: 'widgetOffer.spaces',
                defaultMessage: 'Pomieszczenia'
              })}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginTop: '32px'
              }}>
              {offerDetail.flat.flatSpaces.map((space: any) => (
                <Box>
                  <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                    {space.nameLocal}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {offerDetail?.flat?.geoposition && (
          <Box sx={{ display: 'flex', justifyItems: 'center' }}>
            <OfferMap geoposition={getLatLng(offerDetail?.flat?.geoposition)} />
          </Box>
        )}
        {validToDate.isAfter(today) ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '36px',
              gap: '28px'
            }}>
            <Button variant='text' color='error' onClick={() => navigate(`/offer/${id}/decline`)}>
              {formatMessage({
                id: 'offer.decline',
                defaultMessage: 'Odrzuć'
              })}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              variant='contained'
              sx={{ width: '215px' }}
              onClick={handleAcceptOffer}>
              {formatMessage({
                id: 'offer.accept',
                defaultMessage: 'Zaakceptuj'
              })}
            </LoadingButton>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
}

export default OfferDetailsView
