import { FC, useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Section, { SectionButton, SectionCover } from 'components/Section'
import { useBoardsList, useBoardsListPosts } from 'services/boards/boards.hooks'
import InfoIndicator from 'components/InfoIndicator'
import { EventOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'

const WidgetAnnouncements: FC = () => {
  const { data = [] } = useBoardsList()
  const { boardsListPosts, isLoading } = useBoardsListPosts(data.map(({ id }) => id))
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const [latestPost, setLatestPost] = useState<any>(null)

  useEffect(() => {
    if (boardsListPosts.length) {
      const getLatestPost = boardsListPosts.reduce(
        (latest, post) => (new Date(post.eventDate) > new Date(latest.eventDate) ? post : latest),
        boardsListPosts[0]
      )
      setLatestPost(getLatestPost)
    } else {
      setLatestPost(null)
    }
  }, [boardsListPosts])

  return (
    <>
      <InfoIndicator
        helperText={formatMessage({
          id: 'WidgetAnnouncements.noEvents',
          defaultMessage: 'Brak wydarzeń'
        })}
        Icon={EventOutlined}
        isVisible={!boardsListPosts.length && !isLoading}
      />
      <Section
        key={`${latestPost?.board.id}/${latestPost?.id}`}
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          px: 0
        }}>
        <Box width='100%' px={2} mb={1}>
          {!!latestPost?.multimedia.length && (
            <SectionCover src={latestPost?.multimedia[0].multimedia} alt='' />
          )}
        </Box>
        <Typography sx={{ width: '100%', px: 2, fontWeight: 500 }}>{latestPost?.title}</Typography>
        <Typography variant='body2' sx={{ width: '100%', px: 2 }}>
          {dayjs(latestPost?.eventDate).format('DD/MM/YYYY HH:mm')}
        </Typography>
        <SectionButton onClick={() => navigate(`/board/${latestPost?.board.id}/${latestPost?.id}`)}>
          {latestPost?.createdBy.firstName}
        </SectionButton>
      </Section>
    </>
  )
}

export default WidgetAnnouncements
