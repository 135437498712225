import { FC } from 'react'
import { Button } from '@mui/material'
import { useIntl } from 'react-intl'
import Section from 'components/Section'
import { useNavigate } from 'react-router-dom'

const WidgetFault: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <Section
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '15px'
      }}>
      <Button variant='contained' fullWidth onClick={() => navigate('/faults/report/add')}>
        {formatMessage({
          id: 'faultWidget.reportFault',
          defaultMessage: 'Zgłoś userkę'
        })}
      </Button>
    </Section>
  )
}

export default WidgetFault
