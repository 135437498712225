import { FC } from 'react'
import { Typography, Button } from '@mui/material'
import { useIntl } from 'react-intl'
import Section from 'components/Section'
import { useDocumentsList } from 'services/documents/documents.hooks'

const WidgetGuide: FC = () => {
  const { formatMessage } = useIntl()
  const { data: attachmentsList = [] } = useDocumentsList()

  const clientPanelItem = attachmentsList.find(
    (item) => item.displayOnMainPageInClientPanel === true
  )

  if (!clientPanelItem) {
    return null
  }

  return (
    <Section
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '15px'
      }}>
      <Typography variant='h5' fontWeight={500}>
        {formatMessage({
          id: 'guideWidget.rentralGuide',
          defaultMessage: 'Przewodnik najmu'
        })}
      </Typography>
      <Button variant='outlined' fullWidth href={clientPanelItem?.file} target='_blank'>
        {formatMessage({
          id: 'guideWidget.openGuide',
          defaultMessage: 'Otwórz przewodnik'
        })}
      </Button>
    </Section>
  )
}

export default WidgetGuide
