import { FC, useMemo, useState, useCallback, useEffect } from 'react'
import {
  Box,
  Typography,
  LinearProgress,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Badge,
  IconButton,
  Tooltip,
  useMediaQuery
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import DesktopNavigation from 'components/DesktopNavigation'
import Icon from 'components/Icon'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import { useIntl } from 'react-intl'
import PaperButton, { PaperButtonList } from 'components/PaperButton'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import MobileBottomFooter from 'components/MobileBottomFooter'

import {
  useHelpdeskCategoriesList,
  useHelpdeskTcketsCreate,
  useHelpdeskTicketsList,
  useHelpdeskTicketsMessagesCreate
} from 'services/helpdesk/hooks'
import { useLeaseContractFlats } from 'services/contracts/hooks/useLeaseContractsFlats'
import { MobileDatePicker, MobileTimePicker, TimeView } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import weekday from 'dayjs/plugin/weekday'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Holidays from 'date-holidays'
import { useHelpdeskTicketsAvailability } from 'services/helpdesk/hooks/useHelpdeskTicketsAvailability'
import theme from 'styles/theme'
import { FAULT_TOPICS, topicsWithoutAvailabilityDate } from './defects.consts'
import { useLanguage } from 'services/i18n/i18n.hooks'

dayjs.extend(isSameOrAfter)
dayjs.extend(weekday)
dayjs.extend(customParseFormat)

const disableWeekends = (date: dayjs.Dayjs) => {
  const day = new Date(dayjs(date).format()).getDay()
  const isWeekend = day === 0 || day === 6
  const isToday = dayjs().isSame(date, 'day') // Check if the date is today

  return isWeekend || isToday // Disable weekends and today's date
}

const hd = new Holidays('PL')
const holidays = hd.getHolidays(dayjs().year()).map((holiday) => holiday.date)

const calculateMaxDate = (daysToAdd: number) => {
  let date = dayjs()
  let addedDays = 0

  while (addedDays < daysToAdd) {
    date = date.add(1, 'day')
    if (date.day() !== 0 && date.day() !== 6 && !holidays.includes(date.format('YYYY-MM-DD'))) {
      addedDays++
    }
  }

  return date
}

const handleHoursRestriction = (timeValue: number, clockType: TimeView) => {
  const hour = dayjs(timeValue).hour()
  const minute = dayjs(timeValue).minute()

  if (clockType === 'hours') {
    if (hour < 9 || hour > 17) {
      return true
    }
  }

  if (clockType === 'minutes') {
    if (hour === 9 && minute < 30) {
      return true
    }
    if (hour === 17 && minute > 0) {
      return true
    }
  }

  return false
}

const DefectsReportFault: FC = () => {
  const { formatMessage } = useIntl()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [parentCategoryId, setParentCategoryId] = useState<number>(0)
  const [contractId, setContractId] = useState<number>(0)
  const { createTicket } = useHelpdeskTcketsCreate()
  const { refetchTicketsList } = useHelpdeskTicketsList()
  const { createMessage } = useHelpdeskTicketsMessagesCreate()
  const { categoriesList } = useHelpdeskCategoriesList()
  const { leaseContractsList } = useLeaseContractsList()
  const { leaseContractsFlats } = useLeaseContractFlats(contractId)
  const { createAvailability } = useHelpdeskTicketsAvailability()
  const { language } = useLanguage()

  const formik = useFormik<{
    categories: number[]
    description: string
    topics: FAULT_TOPICS[]
    topic: string
    progress: number
    step: number
    flatId: number
    attachments: File[]
    availableDates: { availableDate: string; availableHourFrom: number; availableHourTo: number }[]
  }>({
    initialValues: {
      topics: [],
      topic: '',
      categories: [],
      description: '',
      progress: 0,
      step: 0,
      flatId: 0,
      attachments: [],
      availableDates: []
    },
    onSubmit: async ({ attachments, ...values }, { setSubmitting }) => {
      setSubmitting(true)
      const { data }: any = await createTicket({
        faultCategory: values.categories.at(-1) as number,
        topic: values.topic,
        description: values.description,
        flatId: values.flatId
      })

      if (!!attachments.length) {
        for (const attachment of attachments) {
          await createMessage({
            message: '',
            attachment,
            ticketId: data.id
          })
        }
      }

      if (values.availableDates.length) {
        values.availableDates
          .filter((date) => date.availableDate)
          .forEach((date) => {
            const hourFrom = date.availableHourFrom
            const dayjsHourFrom = dayjs(hourFrom)
            const availableFrom = dayjs(
              new Date(dayjs(date.availableDate).format()).setHours(
                hourFrom ? dayjsHourFrom.hour() : 0,
                hourFrom ? dayjsHourFrom.minute() : 0,
                0
              )
            ).format()
            const hourTo = date.availableHourTo
            const dayjsHourTo = dayjs(hourTo)
            const availableTo = dayjs(
              new Date(dayjs(date.availableDate).format()).setHours(
                hourTo ? dayjsHourTo.hour() : 0,
                hourTo ? dayjsHourTo.minute() : 0,
                0
              )
            ).format()
            createAvailability({
              ticketId: data.id,
              availableFrom,
              availableTo
            })
          })
      }

      formik.setFieldValue('step', 3)
      refetchTicketsList()
      setSubmitting(false)
    }
  })

  const navigate = useNavigate()

  const handleNextStep = () => {
    if (formik.values.categories.includes(parentCategoryId)) {
      formik.setFieldValue(
        'categories',
        formik.values.categories.filter((category) => category !== parentCategoryId)
      )
    } else {
      formik.setFieldValue('categories', [...formik.values.categories, parentCategoryId])
    }
    formik.setFieldValue('progress', 33)
    return setParentCategoryId(0)
  }

  const categories = useMemo(() => {
    if (!!formik.values.categories.length) {
      const currentParentCategoryId = formik.values.categories.at(-1)
      return categoriesList.filter(
        ({ parentCategoryId }) => parentCategoryId === currentParentCategoryId
      )
    }

    return categoriesList.filter(({ parentCategoryId }) => !parentCategoryId)
  }, [categoriesList, formik])

  const getTitle = useCallback(() => {
    if (!!formik.values.categories.length) {
      const currentParentCategoryId = formik.values.categories.at(-1)
      return categoriesList.find(({ id }) => id === currentParentCategoryId)?.name || ''
    }

    return ''
  }, [categoriesList, formik])

  const title = useMemo(() => getTitle(), [getTitle])

  useEffect(() => {
    if (!!leaseContractsFlats.length && formik.values.flatId === 0) {
      formik.setFieldValue('flatId', leaseContractsFlats[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseContractsFlats])

  return (
    <>
      <DesktopNavigation
        label={formatMessage({
          id: 'defectsReportFault.fault',
          defaultMessage: 'Usterka'
        })}
      />
      <BackNavigationHeader
        label={formatMessage({
          id: 'defectsReportFault.fault',
          defaultMessage: 'Usterka'
        })}
      />
      <BackNavigationContent>
        <Box px={2}>
          <LinearProgress variant='determinate' value={formik.values.progress} />
        </Box>
        {formik.values.step === 0 && (
          <>
            <Box px={2}>
              <Typography
                variant='button'
                color='primary.main'
                fontWeight={600}
                sx={{ display: 'block', mt: 4, minHeight: '26px' }}>
                {title}
              </Typography>
              <Typography variant='h5' fontWeight={600} mb={3}>
                {formatMessage({
                  id: 'defectsReportFault.selectCategory',
                  defaultMessage: 'Wybierz kategorię'
                })}
              </Typography>
              <PaperButtonList>
                {categories.map(({ id, name, nameLocal, iconName }) => (
                  <PaperButton
                    key={id}
                    label={language === 'pl' ? nameLocal : name}
                    icon={(sx) => <Icon name={iconName} sx={sx} />}
                    isActive={parentCategoryId === id}
                    onClick={() => {
                      const topic = language === 'pl' ? nameLocal : name
                      setParentCategoryId(id)
                      formik.setFieldValue('topics', [...formik.values.topics, topic])
                    }}
                  />
                ))}
              </PaperButtonList>
            </Box>
            <MobileBottomFooter>
              {!!categoriesList.filter((category) =>
                !!parentCategoryId
                  ? category.parentCategoryId === parentCategoryId
                  : !!category.parentCategoryId
              ).length ? (
                <Button
                  data-cy='button-choose-category'
                  onClick={handleNextStep}
                  color='secondary'
                  fullWidth
                  variant='outlined'
                  disabled={!parentCategoryId}>
                  {formatMessage({
                    id: 'defectsReportFault.category',
                    defaultMessage: 'Nastepnie: WYBIERZ KATEGORIĘ'
                  })}
                </Button>
              ) : (
                <Button
                  data-cy='button-add-description'
                  onClick={() => {
                    formik.setFieldValue('step', 1)
                    formik.setFieldValue(
                      'progress',
                      topicsWithoutAvailabilityDate.includes(formik.values.categories[0]) ? 100 : 66
                    )
                    formik.setFieldValue('categories', [
                      ...formik.values.categories,
                      parentCategoryId
                    ])
                    formik.setFieldValue('topic', formik.values.topics.slice(-1)[0])
                  }}
                  color='secondary'
                  fullWidth
                  variant='outlined'
                  disabled={!parentCategoryId}>
                  {formatMessage({
                    id: 'defectsReportFault.step',
                    defaultMessage: 'Następnie: Dodaj opis'
                  })}
                </Button>
              )}
            </MobileBottomFooter>
          </>
        )}
        {formik.values.step === 1 && (
          <>
            <Box px={2}>
              <TextField
                inputProps={{ 'data-cy': 'input-topic' }}
                name='topic'
                defaultValue={title}
                onChange={formik.handleChange}
                value={formik.values.topic}
                label={formatMessage({
                  id: 'defectsReportFault.topic',
                  defaultMessage: 'Temat'
                })}
                sx={{ mt: 4 }}
                fullWidth
              />
              <TextField
                inputProps={{ 'data-cy': 'input-description' }}
                name='description'
                onChange={formik.handleChange}
                value={formik.values.description}
                label={formatMessage({
                  id: 'additionalServicesProtocolSpaces.form.comment',
                  defaultMessage: 'Opis'
                })}
                sx={{ mt: 4 }}
                fullWidth
                rows={5}
                multiline
              />
              <FormControl fullWidth sx={{ mt: 4 }}>
                <InputLabel>
                  {formatMessage({
                    id: 'defectsReportFault.contract',
                    defaultMessage: 'Umowa'
                  })}
                </InputLabel>
                <Select
                  data-cy='select-lease'
                  value={contractId}
                  label={formatMessage({
                    id: 'defectsReportFault.contract',
                    defaultMessage: 'Umowa'
                  })}
                  onChange={(event) => setContractId(Number(event.target.value))}>
                  {leaseContractsList
                    .filter(({ leaseStatus }) => leaseStatus === 0)
                    .map(({ id, contractNumber }) => (
                      <MenuItem key={contractNumber} value={id}>
                        {contractNumber}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box mx={-1}>
                {formik.values.attachments.map((image, key) => (
                  <Badge
                    color='primary'
                    key={`${image.name}_${key}`}
                    sx={{
                      '.MuiBadge-badge': {
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%'
                      },
                      mt: 3,
                      mx: 1
                    }}
                    badgeContent={
                      <IconButton
                        size='small'
                        onClick={() => {
                          formik.setFieldValue(
                            'attachments',
                            formik.values.attachments.filter((_, k) => k !== key)
                          )
                        }}>
                        <Close fontSize='small' color='secondary' />
                      </IconButton>
                    }>
                    <Tooltip title={image.name} placement='top'>
                      <Box
                        sx={{
                          width: '96px',
                          height: '96px',
                          backgroundImage: `url("${URL.createObjectURL(image)}")`,
                          borderRadius: 1,
                          border: '1px solid',
                          borderColor: 'grey.400',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover'
                        }}
                      />
                    </Tooltip>
                  </Badge>
                ))}
              </Box>
              {formik.values.attachments.length < 5 && (
                <Input
                  data-cy='input-photo'
                  fullWidth
                  type='file'
                  sx={{ mt: 2 }}
                  placeholder='Dodaj zdjęcie'
                  inputProps={{
                    accept: 'image/*'
                  }}
                  name='attachment'
                  onChange={(e: any) => {
                    if (!!e.target.files.length) {
                      formik.setFieldValue('attachments', [
                        ...formik.values.attachments,
                        e.target.files[0]
                      ])

                      return (e.target.value = '')
                    }

                    return (e.target.value = '')
                  }}
                />
              )}
              <Typography fontSize={12} color='grey' mt={1}>
                {formatMessage({
                  id: 'common.file.multimedia',
                  defaultMessage: 'Pliki w formacie .jpg, .png, .jpeg, .webp'
                })}
              </Typography>
            </Box>
            <MobileBottomFooter>
              {topicsWithoutAvailabilityDate.includes(formik.values.categories[0]) ? (
                <LoadingButton
                  data-cy='submit-without-date'
                  loading={formik.isSubmitting}
                  onClick={() => formik.submitForm()}
                  color='primary'
                  fullWidth
                  variant='contained'
                  disabled={!formik.values.flatId}>
                  {formatMessage({
                    id: 'defectsReportFault.submit',
                    defaultMessage: 'Zgłoś'
                  })}
                </LoadingButton>
              ) : (
                <Button
                  data-cy='button-choose-date'
                  onClick={() => {
                    formik.setFieldValue('step', 2)
                    formik.setFieldValue('progress', 100)
                  }}
                  color='secondary'
                  fullWidth
                  variant='outlined'
                  disabled={!formik.values.flatId}>
                  {formatMessage({
                    id: 'defectsReportFault.step.availableDate',
                    defaultMessage: 'Nastepnie: Wybierz dogodny termin naprawy'
                  })}
                </Button>
              )}
            </MobileBottomFooter>
          </>
        )}
        {formik.values.step === 2 && (
          <>
            <Box px={2} py={2}>
              <Typography variant='h5' fontWeight={600} mb={2} mt={isDesktop ? 4 : 0}>
                {formatMessage({
                  id: 'defectsReportFault.selectDate',
                  defaultMessage: 'Wybierz dogodny termin naprawy'
                })}
              </Typography>
              {Array.from({ length: 3 }).map((_, i) => (
                <Box
                  key={i}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: isDesktop ? 2 : 0.5,
                      flexDirection: isDesktop ? 'row' : 'column',
                      width: '100%'
                    }}>
                    <MobileDatePicker
                      maxDate={calculateMaxDate(3)}
                      data-cy={`input-date-${i}`}
                      sx={{ my: isDesktop ? 2 : 1 }}
                      disablePast
                      value={
                        formik.values.availableDates[i]?.availableDate
                          ? dayjs(formik.values.availableDates[i].availableDate)
                          : undefined
                      }
                      onChange={(value) => {
                        formik.setFieldValue(`availableDates[${i}].availableDate`, value)
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          inputProps: {
                            'data-cy': `input-date-${i}`
                          }
                        }
                      }}
                      label={formatMessage({
                        id: 'defectsReportFault.availableDate',
                        defaultMessage: 'Data'
                      })}
                      shouldDisableDate={disableWeekends}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2
                      }}>
                      <MobileTimePicker
                        data-cy={`input-time-from-${i}`}
                        minutesStep={30}
                        sx={{ my: isDesktop ? 2 : 1 }}
                        value={
                          formik.values.availableDates[i]?.availableHourFrom
                            ? formik.values.availableDates[i].availableHourFrom
                            : undefined
                        }
                        onChange={(value) => {
                          formik.setFieldValue(`availableDates[${i}].availableHourFrom`, value)
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            inputProps: {
                              'data-cy': `input-time-from-${i}`
                            }
                          }
                        }}
                        label={formatMessage({
                          id: 'defectsReportFault.availableHourFrom',
                          defaultMessage: 'Od godziny'
                        })}
                        shouldDisableTime={handleHoursRestriction}
                        disabled={!formik.values.availableDates[i]}
                      />
                      <MobileTimePicker
                        data-cy={`input-time-to-${i}`}
                        minutesStep={30}
                        sx={{ my: isDesktop ? 2 : 1 }}
                        value={
                          formik.values.availableDates[i]?.availableHourTo
                            ? formik.values.availableDates[i].availableHourTo
                            : undefined
                        }
                        onChange={(value) => {
                          formik.setFieldValue(`availableDates[${i}].availableHourTo`, value)
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            inputProps: {
                              'data-cy': `input-time-to-${i}`
                            }
                          }
                        }}
                        label={formatMessage({
                          id: 'defectsReportFault.availableHourTo',
                          defaultMessage: 'Do godziny'
                        })}
                        shouldDisableTime={handleHoursRestriction}
                        disabled={!formik.values.availableDates[i]}
                      />
                    </Box>
                  </Box>
                  {i !== 2 && (
                    <Typography variant='overline' fontWeight={600}>
                      {formatMessage({
                        id: 'defectsReportFault.or',
                        defaultMessage: 'Lub'
                      })}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
            <MobileBottomFooter>
              {formik.values.availableDates.length! ? (
                <LoadingButton
                  data-cy='submit-with-date'
                  loading={formik.isSubmitting}
                  onClick={() => formik.submitForm()}
                  color='primary'
                  fullWidth
                  variant='contained'
                  disabled={!formik.values.flatId}>
                  {formatMessage({
                    id: 'defectsReportFault.submit',
                    defaultMessage: 'Zgłoś'
                  })}
                </LoadingButton>
              ) : (
                <LoadingButton
                  data-cy='button-skip-and-submit'
                  onClick={() => formik.submitForm()}
                  loading={formik.isSubmitting}
                  color='primary'
                  fullWidth
                  disabled={!formik.values.flatId}
                  variant='text'>
                  {formatMessage({
                    id: 'defectsReportFault.skipAndSubmit',
                    defaultMessage: 'Pomiń i zgłoś'
                  })}
                </LoadingButton>
              )}
            </MobileBottomFooter>
          </>
        )}
        {formik.values.step === 3 && (
          <>
            <Box
              px={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '80vh',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}>
              <Typography variant='h4' fontWeight={500} mb={3}>
                {formatMessage({
                  id: 'defectsReportFault.success',
                  defaultMessage: 'Usterka została zgłoszona'
                })}
              </Typography>
            </Box>
            <MobileBottomFooter>
              <Button
                data-cy='button-back'
                onClick={() => navigate('/faults')}
                color='primary'
                fullWidth
                variant='contained'>
                {formatMessage({
                  id: 'defectsReportFault.back',
                  defaultMessage: 'Wróć do strony startowej'
                })}
              </Button>
            </MobileBottomFooter>
          </>
        )}
      </BackNavigationContent>
    </>
  )
}

export default DefectsReportFault
