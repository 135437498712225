import { FC } from 'react'
import { Typography, Button, Box, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import DesktopNavigation from 'components/DesktopNavigation'
import { useHelpdeskTicketsList, useHelpdeskTicketsMessagesList } from 'services/helpdesk/hooks'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import MobileBottomFooter from 'components/MobileBottomFooter'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { MuiMarkdown } from 'mui-markdown'
import { useHelpdeskTicketsClientFeedback } from 'services/helpdesk/hooks/useHelpdeskTicketsClientFeedback'

const Defect: FC = () => {
  const { formatMessage } = useIntl()
  const { id } = useParams()
  const { getTicket, getSatusColors } = useHelpdeskTicketsList()
  const { getTicketFeedback } = useHelpdeskTicketsClientFeedback(Number(id))
  const { ticketsMessagesList } = useHelpdeskTicketsMessagesList(Number(id))
  const ticket = getTicket(Number(id))
  const ticketFeedback = getTicketFeedback
  const handleOpenAttachment = (event: any, attachment: string) => {
    event.preventDefault()
    window.open(attachment, '_blank')
  }

  if (ticket) {
    return (
      <>
        <DesktopNavigation
          desktopNavigateTo='/'
          label={
            <>
              {ticket.topic}
              <Typography
                variant='caption'
                component='span'
                sx={{
                  display: 'inline-flex',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  fontWeight: 500,
                  marginLeft: '8px',
                  ...getSatusColors(ticket.status.code)
                }}>
                {ticket.status.name}
              </Typography>
            </>
          }
        />
        <BackNavigationHeader
          label={
            <>
              {ticket.topic}{' '}
              <Typography
                variant='caption'
                component='span'
                sx={{
                  display: 'inline-flex',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  fontWeight: 500,
                  marginLeft: '8px',
                  ...getSatusColors(ticket.status.code)
                }}>
                {ticket.status.name}
              </Typography>
            </>
          }
        />
        <BackNavigationContent>
          <Box
            sx={{
              maxHeight: '80vh',
              overflowY: 'auto',
              paddingTop: 'env(safe-area-inset-top)',
              paddingBottom: 'calc(env(safe-area-inset-top) + 6rem)'
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column'
              }}>
              <Tooltip title={dayjs(ticket.createdAt).format('DD/MM/YYYY HH:mm:ss')}>
                <Typography
                  sx={{
                    border: '1px solid rgba(0,0,0,.12)',
                    p: 2,
                    borderRadius: 1,
                    maxWidth: '70%',
                    width: 'auto'
                  }}>
                  {ticket.description}
                </Typography>
              </Tooltip>
              <Typography variant='body2' display='block'>
                {ticket.authorPerson.firstName}
              </Typography>
            </Box>
            <Box>
              {ticketsMessagesList.map(({ createdAt, message, author, attachment }) => (
                <Box
                  key={createdAt}
                  mt={2}
                  sx={{
                    display: 'flex',
                    justifyContent:
                      author?.id === ticket.authorPerson.id ? 'flex-end' : 'flex-start',
                    alignItems: author?.id === ticket.authorPerson.id ? 'flex-end' : 'flex-start',
                    flexDirection: 'column'
                  }}>
                  <Tooltip title={dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss')}>
                    <Box
                      sx={{
                        border:
                          author?.id === ticket.authorPerson.id
                            ? '1px solid rgba(0,0,0,.12)'
                            : '#F2F6FF',
                        backgroundColor: author?.id === ticket.authorPerson.id ? '#fff' : '#F2F6FF',
                        p: 2,
                        borderRadius: 1,
                        maxWidth: '70%',
                        width: 'auto'
                      }}>
                      <MuiMarkdown>{message}</MuiMarkdown>
                      {attachment && attachment.includes('.pdf') ? (
                        <Box
                          sx={{
                            position: 'relative',
                            display: 'inline-block',
                            maxHeight: '200px',
                            maxWidth: '100%',
                            width: 'auto',
                            marginTop: '10px',
                            cursor: 'pointer'
                          }}>
                          <iframe
                            title='attachment'
                            src={attachment}
                            style={{
                              height: '100%',
                              width: '100%',
                              border: 'none',
                              overflow: 'hidden',
                              overflowY: 'hidden'
                            }}
                            scrolling='no'
                          />
                          <Box
                            onClick={(event) => handleOpenAttachment(event, attachment)}
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'transparent'
                            }}
                          />
                        </Box>
                      ) : attachment ? (
                        <a href={attachment} target='_blank' rel='noopener noreferrer'>
                          <Box
                            sx={{
                              maxHeight: '200px',
                              maxWidth: '100%',
                              width: 'auto',
                              mt: 1,
                              cursor: 'pointer'
                            }}
                            component='img'
                            src={attachment}
                            alt={ticket.status.name}
                          />
                        </a>
                      ) : null}
                    </Box>
                  </Tooltip>
                  <Typography variant='body2' display='block'>
                    {author?.firstName ??
                      formatMessage({
                        id: 'defect.admin',
                        defaultMessage: 'Administrator'
                      })}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <MobileBottomFooter>
            {ticket?.status.code === 'READY' && (ticketFeedback ?? []).length > 0 ? null : (
              <Typography variant='body2' textAlign='center'>
                {formatMessage({
                  id: 'defect.replayTime',
                  defaultMessage:
                    'Wiadomość została przekazana do osoby odpowiedzialnej, niebawem wrócimy z odpowiedzią'
                })}
              </Typography>
            )}
            {ticket?.status.code === 'READY' ? null : (
              <Button
                component={Link}
                fullWidth
                sx={{ mt: 2 }}
                variant='contained'
                to={`/faults/report/${id}/message`}>
                {formatMessage({
                  id: 'defect.replay',
                  defaultMessage: 'Odpisz'
                })}
              </Button>
            )}
          </MobileBottomFooter>
        </BackNavigationContent>
      </>
    )
  }

  return null
}

export default Defect
