import { FC } from 'react'
import {
  TextField,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  CircularProgress
} from '@mui/material'
import { ExitToApp } from '@mui/icons-material'
import { useNavigate, Navigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { KeyboardArrowRight, ArrowBackIosNew, SupervisorAccount } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import PasswordField from 'components/PasswordField'
import InfoIndicator from 'components/InfoIndicator'
import {
  useMasquarade,
  useMasquaradeUser,
  useMasquaradeToken
} from 'services/masquarade/masquarade.hooks'
import { useSnackbar } from 'notistack'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'

const MasquaradeLogin: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigation = useNavigate()
  const { formatMessage } = useIntl()
  const {
    masquaradeLogin,
    isCanMasqueradeLoading,
    canUseMasquerade,
    masquaradeUsersList,
    isMasquaradeUsersListLoading
  } = useMasquarade()

  const { masquaradeUser, isMasquarade, clearMasquaradeUser } = useMasquaradeUser()
  const { clearMasquaradeToken } = useMasquaradeToken()

  const form = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(
          formatMessage({
            id: 'validation.email',
            defaultMessage: 'Niepoprawny adres email'
          })
        )
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        ),
      password: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      )
    }),
    onSubmit: async (values, formHelpers) => {
      formHelpers.setSubmitting(true)
      try {
        const { data } = await masquaradeLogin(values)
        enqueueSnackbar(
          formatMessage(
            {
              id: 'masquaradeLogin.success',
              defaultMessage: 'Zostałeś zalogwany jak {user}.'
            },
            {
              user: data.user.email
            }
          ),
          {
            variant: 'success'
          }
        )
        navigation('/user/account')
      } catch (e) {
        enqueueSnackbar(
          formatMessage({
            id: 'masquaradeLogin.error',
            defaultMessage: 'Błąd logowania. Podano nieprawidłowy login lub hasło.'
          }),
          {
            variant: 'error'
          }
        )
      }

      formHelpers.setSubmitting(false)
    }
  })

  if (!isCanMasqueradeLoading && !canUseMasquerade) {
    return <Navigate to='/user/account' />
  }

  if (isMasquarade) {
    return (
      <>
        <BackNavigationHeader
          label={formatMessage({
            id: 'userProfile.profile',
            defaultMessage: 'Profile'
          })}
        />
        <BackNavigationContent>
          <Box pt='env(safe-area-inset-top)'>
            <Typography fontWeight={500} mb={4}>
              {formatMessage({
                id: 'masquaradeLogin.userInfo',
                defaultMessage: 'Maskarada jako:'
              })}
            </Typography>
            <TextField
              name='email'
              value={masquaradeUser.email}
              fullWidth
              placeholder={formatMessage({
                id: 'masquaradeLogin.form.email',
                defaultMessage: 'Email'
              })}
              label={formatMessage({
                id: 'masquaradeLogin.form.email',
                defaultMessage: 'Email'
              })}
              disabled
              sx={{ mb: 3 }}
            />
            {!!masquaradeUser.firstName.length && (
              <TextField
                name='firstName'
                value={masquaradeUser.firstName}
                fullWidth
                placeholder={formatMessage({
                  id: 'masquaradeLogin.form.firstName',
                  defaultMessage: 'Imię'
                })}
                label={formatMessage({
                  id: 'masquaradeLogin.form.firstName',
                  defaultMessage: 'Imię'
                })}
                disabled
                sx={{ mb: 3 }}
              />
            )}
            {!!masquaradeUser.lastName.length && (
              <TextField
                name='lastName'
                value={masquaradeUser.lastName}
                fullWidth
                placeholder={formatMessage({
                  id: 'masquaradeLogin.form.lastName',
                  defaultMessage: 'Nazwisko'
                })}
                label={formatMessage({
                  id: 'masquaradeLogin.form.lastName',
                  defaultMessage: 'Nazwisko'
                })}
                disabled
                sx={{ mb: 3 }}
              />
            )}
            <Button
              fullWidth
              color='error'
              onClick={() => {
                form.resetForm()
                clearMasquaradeUser()
                clearMasquaradeToken()
              }}
              startIcon={<ExitToApp />}>
              {formatMessage({
                id: 'masquaradeLogin.logout',
                defaultMessage: 'Usuń maskaradę'
              })}
            </Button>
          </Box>
        </BackNavigationContent>
      </>
    )
  }

  if (!form.values.email) {
    if (isMasquaradeUsersListLoading) {
      return (
        <>
          <BackNavigationHeader
            label={formatMessage({
              id: 'userProfile.profile',
              defaultMessage: 'Profile'
            })}
          />
          <BackNavigationContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: 'env(safe-area-inset-top))'
              }}>
              <CircularProgress />
            </Box>
          </BackNavigationContent>
        </>
      )
    }
    if (!!masquaradeUsersList.length) {
      return (
        <>
          <BackNavigationHeader
            label={formatMessage({
              id: 'userProfile.profile',
              defaultMessage: 'Profile'
            })}
          />
          <BackNavigationContent>
            <Box pt='env(safe-area-inset-top)'>
              <Typography fontWeight={500} mb={4}>
                {formatMessage({
                  id: 'masquaradeLogin.selectUser',
                  defaultMessage: 'Wybierz użytkownika na ktróym chcesz zastosować maskaradę:'
                })}
              </Typography>
              <List
                sx={{
                  '.MuiListItem-root:first-of-type': {
                    borderTop: '1px solid #CDCDCD'
                  }
                }}>
                {masquaradeUsersList.map(({ email }) => (
                  <ListItem
                    key={email}
                    disablePadding
                    sx={{
                      borderBottom: '1px solid #CDCDCD'
                    }}
                    secondaryAction={<KeyboardArrowRight color='disabled' />}
                    onClick={() => form.setFieldValue('email', email)}>
                    <ListItemButton sx={{ py: 2 }}>
                      <ListItemText primary={email} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </BackNavigationContent>
        </>
      )
    }

    return (
      <>
        <BackNavigationHeader
          label={formatMessage({
            id: 'userProfile.profile',
            defaultMessage: 'Profile'
          })}
        />
        <BackNavigationContent>
          <Box pt='env(safe-area-inset-top)'>
            <InfoIndicator
              helperText={formatMessage({
                id: 'masquaradeLogin.supervisorAccount',
                defaultMessage:
                  'Brak użytkownków na których możesz zastosować maskaradę. Zaloguj się do CRM i ustaw hasło tymczasowe dla wybranego użytkownika.'
              })}
              Icon={SupervisorAccount}
              isVisible
            />
          </Box>
        </BackNavigationContent>
      </>
    )
  }

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userProfile.profile',
          defaultMessage: 'Profile'
        })}
      />
      <BackNavigationContent>
        <form onSubmit={form.handleSubmit}>
          <Box
            sx={{
              mb: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              pt: 'env(safe-area-inset-top))'
            }}>
            <IconButton onClick={() => form.setFieldValue('email', '')}>
              <ArrowBackIosNew />
            </IconButton>
            <Typography sx={{ mx: 1, flex: 1, fontWeight: 500 }}>
              {formatMessage({
                id: 'masquaradeLogin.backToList',
                defaultMessage: 'Wróć do listy'
              })}
            </Typography>
          </Box>

          <TextField
            name='email'
            value={form.values.email}
            onChange={form.handleChange}
            fullWidth
            placeholder={formatMessage({
              id: 'masquaradeLogin.form.email',
              defaultMessage: 'Email'
            })}
            label={formatMessage({
              id: 'masquaradeLogin.form.email',
              defaultMessage: 'Email'
            })}
            sx={{ mb: 3 }}
            error={!!form.errors.email}
            helperText={!!form.errors.email && form.errors.email}
            disabled
          />
          <PasswordField
            name='password'
            value={form.values.password}
            onChange={form.handleChange}
            fullWidth
            sx={{ mb: 3 }}
            label={formatMessage({
              id: 'masquaradeLogin.form.password',
              defaultMessage: 'Hasło'
            })}
            disabled={form.isSubmitting}
            error={!!form.errors.password}
            helperText={!!form.errors.password && form.errors.password}
          />
          <LoadingButton
            fullWidth
            size='large'
            variant='contained'
            sx={{ mb: 1 }}
            type='submit'
            disabled={form.isSubmitting}>
            {formatMessage({
              id: 'masquaradeLogin.form.submit',
              defaultMessage: 'ZALOGUJ SIĘ'
            })}
          </LoadingButton>
        </form>
      </BackNavigationContent>
    </>
  )
}

export default MasquaradeLogin
