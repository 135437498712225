import { FC, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { CheckCircle, SavingsOutlined } from '@mui/icons-material'

import Section from 'components/Section'
import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'
import { useIntl } from 'react-intl'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import { useSchedulesWithDocumnets } from 'services/contracts/hooks/useSchedulesWithDocumnets'

const AdditionalServicesPaymentStatus: FC = () => {
  const { formatMessage } = useIntl()
  const { activeLeaseContractId } = useActiveLeaseContractId()

  const { accountBalance, isLoading } = useSchedulesWithDocumnets({
    activeLeaseContractId,
    isPaid: false
  })

  const { getActiveContract } = useLeaseContractsList()

  const activeContract = useMemo(
    () => getActiveContract(activeLeaseContractId),
    [activeLeaseContractId, getActiveContract]
  )

  if (isLoading) {
    return null
  }

  if (accountBalance > 0) {
    return (
      <>
        <Section
          sx={{
            py: 4,
            borderColor: '#feebeb',
            backgroundColor: '#feebeb'
          }}>
          <Box>
            <Typography variant='body2'>
              {formatMessage({
                id: 'additionalServicesPaymentStatus.toPay',
                defaultMessage: 'Do opłacenia'
              })}
            </Typography>
            <Typography variant='h5' fontWeight={600}>
              {`${accountBalance.toFixed(2)}`.replace('-', '')} {activeContract?.paymentCurrency}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '70px',
              height: '70px',
              backgroundColor: '#febdbd',
              borderRadius: '50%',
              mr: 2
            }}>
            <SavingsOutlined sx={{ color: '#c80000' }} />
          </Box>
        </Section>
      </>
    )
  }

  return (
    <>
      <Section
        sx={{
          py: 4,
          mb: 8,
          borderColor: '#EDFFFA',
          backgroundColor: '#EDFFFA'
        }}>
        <Box>
          <Typography variant='body2'>
            {formatMessage({
              id: 'additionalServicesPaymentStatus.invoiceStatus',
              defaultMessage: 'Status faktur'
            })}
          </Typography>
          <Typography variant='h5' fontWeight={600}>
            {formatMessage({
              id: 'additionalServicesPaymentStatus.paid',
              defaultMessage: 'Opłacone'
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '70px',
            height: '70px',
            backgroundColor: '#D7FFF4',
            borderRadius: '50%',
            mr: 2
          }}>
          <CheckCircle sx={{ color: '#00B383' }} />
        </Box>
      </Section>
    </>
  )
}

export default AdditionalServicesPaymentStatus
