import { useQuery, useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

type BenefitsListResponse = {
  id: number
  file: string
  isAccepted: string
}

export const useTermsOfUse = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: termsOfUse, ...rest } = useQuery({
    queryKey: ['termsOfUse', masquaradeToken],
    cacheTime: 3600000,
    queryFn: () =>
      apiInstance<BenefitsListResponse>({
        url: '/documents/terms-of-use/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  const { mutateAsync: mutateAsyncTermsOfUse, isLoading: isLoadingTermsOfUse } = useMutation({
    cacheTime: 0,
    mutationFn: (id?: number) =>
      apiInstance<BenefitsListResponse>({
        url: `/documents/terms-of-use/`,
        method: 'POST',
        data: {
          id
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data)
  })

  const hasUserConsented = !!termsOfUse ? Boolean(termsOfUse.isAccepted) : false

  return {
    termsOfUse,
    hasUserConsented,
    mutateAsyncTermsOfUse,
    isLoadingTermsOfUse,
    ...rest
  }
}
