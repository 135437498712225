import { FC } from 'react'
import { Typography, Button, CircularProgress, Box } from '@mui/material'
import { useIntl } from 'react-intl'
import Section from 'components/Section'
import { useBenefitsList } from 'services/benefits/benefits.hooks'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

const WidgetDiscount: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { benefitsList = [], isFetching, isLoading } = useBenefitsList()

  if (isFetching || isLoading) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Section>
    )
  }

  if (benefitsList.length < 1) {
    return null
  }

  const newestBenefit = benefitsList.reduce((latest, current) => {
    return dayjs(current.createdAt).isAfter(dayjs(latest.createdAt)) ? current : latest
  }, benefitsList[0])

  return (
    <Section
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '15px'
      }}>
      <Typography variant='h5' fontWeight={500}>
        {formatMessage({
          id: 'discountWidget.discounts',
          defaultMessage: 'Strefa rabatów'
        })}
      </Typography>
      <Box
        sx={{ width: 'auto', maxHeight: '250px', margin: 'auto' }}
        component='img'
        src={newestBenefit.logoThumbnail}
        loading='lazy'
      />
      <Button variant='outlined' fullWidth onClick={() => navigate(`/discounts`)}>
        {formatMessage({
          id: 'discountWidget.goToDiscounts',
          defaultMessage: 'Przejdź do strefy rabatów'
        })}
      </Button>
    </Section>
  )
}

export default WidgetDiscount
