import { FC } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import DesktopNavigation from 'components/DesktopNavigation'
import Dashboard from 'layouts/Dashboard'
import { UserProtectedRoute } from 'routes'
import {
  WidgetPayments,
  WidgetAnnouncements,
  WidgetDiscount,
  WidgetOffer,
  WidgetGuide,
  WidgetConsultant,
  WidgetFault,
  WidgetBalance
} from 'components/Widgets'

const ComponentName: FC = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  if (isDesktop) {
    return (
      <UserProtectedRoute>
        <Dashboard>
          <DesktopNavigation label='' isMainRoute />
          <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                minWidth: '320px',
                maxWidth: '480px',
                minHeight: 'calc(100vh - 180px)',
                borderRight: '1px solid #E0E0E0',
                pr: 3,
                marginTop: '10px'
              }}>
              <WidgetPayments />
              <WidgetOffer />
              <WidgetBalance />
              <WidgetGuide />
              <WidgetDiscount />
            </Box>
            <Box
              sx={{ width: '100%', pl: 4, maxWidth: '960px', margin: '0 auto', marginTop: '10px' }}>
              <WidgetConsultant />
              <WidgetFault />
              <WidgetAnnouncements />
            </Box>
          </Box>
        </Dashboard>
      </UserProtectedRoute>
    )
  }
  return (
    <UserProtectedRoute>
      <Dashboard>
        <Box sx={{ px: 2, maxWidth: '920px', margin: '0 auto' }}>
          <WidgetOffer />
          <WidgetBalance />
          <WidgetGuide />
          <WidgetDiscount />
          <WidgetPayments />
          <WidgetConsultant />
          <WidgetAnnouncements />
          <WidgetFault />
        </Box>
      </Dashboard>
    </UserProtectedRoute>
  )
}

export default ComponentName
